.buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    column-gap: 20px;

    .generate {
        padding: 12px 24px;
        border: 2px solid lightseagreen;
        background-color: lightseagreen;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-weight: bold;

        &:hover {
            color: lightseagreen;
            background-color: #fff;
        }
    }

    .draft-post {
        cursor: pointer;
        text-decoration: underline;
        color: lightseagreen;
        font-weight: bold;
    }
}
