#root {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    column-gap: 35px;
    padding: 35px;
    width: 80%;
}

select {
    width: 350px;
    font-size: 20px;
    padding: 5px;
}
