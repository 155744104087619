.prompt {
    position: relative;
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    width: 75%;
    margin: 15px 0;

    & > * {
        width: 100%;
    }

    h2 {
        margin: 0;
        margin-top: 15px;
    }

    textarea {
        width: 100%;
        max-width: 100%;
        font-size: 20px;
        padding: 15px;
        margin-top: 15px;
    }

    .answer {
        font-size: 20px;
        line-height: 26px;
    }
}
